import { createPinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'

export const store = (app) => {
    let nsItem = localStorage.getItem('nsEditSessions')
    if (nsItem) {
        localStorage.setItem('dsEditSessions', nsItem)
        localStorage.removeItem('nsEditSessions')
    }
    const pinia = createPinia()
    pinia.use(createPersistedStatePlugin({
        serialize: (store) => {
            return JSON.stringify(store, replacer);
        },
        deserialize: (serializedObj) => {
            return JSON.parse(serializedObj, reviver)
        },
        persist: false,
    }))
    app.use(pinia)
}

function replacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()),
        };

    } else {
        return value;
    }
}

function reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}