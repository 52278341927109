<!--
 Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <router-link to="/">SST Repository</router-link> |
  <router-link
    :to="{
      name: recentGraphURI !== undefined ? 'SSTDatasetGraph' : 'SSTDataset',
      params:
        recentGraphURI !== undefined
          ? { dsID: recentDatasetID, graphURI: recentGraphURI }
          : { dsID: recentDatasetID },
    }"
    :class="{ dimmed: isRecentDatasetIDIUnknown }"
    >Dataset &amp; Named Graph</router-link
  ><span
    v-if="
      (recentDatasetID && dsServiceSessions.has(recentDatasetID)) ||
      dsEditSessions.has(recentDatasetID)
    "
    >&lowast;</span
  >
  |
  <router-link
    :to="{
      name: 'IBNode',
      params: {
        dsID: recentIBNodeInfo.dsID,
        graphURI: recentIBNodeInfo.graphURI,
        fragment: recentIBNodeInfo.fragment,
      },
    }"
    :class="{ dimmed: isRecentIBNodeInfoUnknown }"
    >IRI or Blank Node</router-link
  >
  |
  <router-link to="/parts">Parts</router-link>
  |
  <router-link to="/about">About</router-link>
</template>

<script>
import { useDatasetEditSessions } from "../stores/dsEditSessions";
import { useRecentStore } from "../stores/recent";

export default {
  setup() {
    const dsEditSessions = useDatasetEditSessions();
    const recentStore = useRecentStore();
    return {
      dsServiceSessions: dsEditSessions.serviceSessions,
      dsEditSessions: dsEditSessions.sessions,
      recentStore,
    };
  },
  beforeMount: function () {
    this.validateRecent();
  },
  beforeUpdate: function () {
    this.validateRecent();
  },
  methods: {
    validateRecent() {
      if (!this.isLoggedInUser()) {
        return;
      }

      this.validateRecentDataset();
      this.validateRecentIBNode();
    },
    validateRecentDataset() {
      if (this.recentStore.recentDatasetID === "") {
        return;
      }
      const sessionID = this.dsServiceSessions.get(
        this.recentStore.recentDatasetID
      );
      const url =
        "/dataset/" +
        this.recentStore.recentDatasetID +
        (sessionID !== undefined ? "/session/" + sessionID : "") +
        (this.recentStore.recentGraphURI !== undefined
          ? "/graph/" +
            encodeURIComponent(
              encodeURIComponent(this.recentStore.recentGraphURI)
            ) // Double escaping
          : "");

      return this.axios
        .get(url, {
          headers: { Prefer: "return=minimal" },
          validateStatus: function (status) {
            return status === 204 || status === 401 || status === 404;
          },
        })
        .then((response) => {
          if (response.status === 404) {
            this.dsServiceSessions.delete(this.recentDatasetID);
            this.recentStore.recentDatasetID = "";
            this.recentStore.recentGraphURI = undefined;
          }
        });
    },
    validateRecentIBNode() {
      if (
        !this.recentStore.recentIBNodeInfo ||
        this.recentStore.recentIBNodeInfo.dsID === ""
      ) {
        return;
      }
      const sessionID = this.dsServiceSessions.get(
        this.recentStore.recentIBNodeInfo.dsID
      );
      const url =
        "/dataset/" +
        this.recentStore.recentIBNodeInfo.dsID +
        (sessionID !== undefined ? "/session/" + sessionID : "") +
        "/graph/" +
        encodeURIComponent(
          encodeURIComponent(this.recentStore.recentIBNodeInfo.graphURI)
        ) + // Double escaping
        "/node/" +
        encodeURIComponent(
          this.fragment === "."
            ? "%2e"
            : encodeURIComponent(this.recentStore.recentIBNodeInfo.fragment)
        );

      return this.axios
        .get(url, {
          headers: { Prefer: "return=minimal" },
          validateStatus: function (status) {
            return status === 204 || status === 401 || status === 404;
          },
        })
        .then((response) => {
          if (response.status === 404 && this.recentStore.recentIBNodeInfo) {
            this.dsServiceSessions.delete(
              this.recentStore.recentIBNodeInfo.dsID
            );
            this.recentStore.recentIBNodeInfo = undefined;
          }
        });
    },
    isLoggedInUser() {
      return this.user !== undefined && this.user.profile !== undefined;
    },
  },
  computed: {
    recentDatasetID() {
      return this.recentStore.recentDatasetID;
    },
    recentGraphURI() {
      return this.recentStore.recentGraphURI;
    },
    isRecentDatasetIDIUnknown() {
      return this.recentDatasetID === "";
    },
    recentIBNodeInfo() {
      const ni = this.recentStore.recentIBNodeInfo;
      return ni !== undefined ? ni : { dsID: "", graphURI: "", fragment: "" };
    },
    isRecentIBNodeInfoUnknown() {
      return (
        this.recentIBNodeInfo === undefined || this.recentIBNodeInfo.dsID === ""
      );
    },
  },
};
</script>

<style>
</style>