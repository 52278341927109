<!--
 Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <header id="nav">
    <nav-menu />
    <div v-if="loggedInUser !== undefined">
      <em>{{ loggedInUser }}</em> |
      <span @click="this.signoutUser()" class="link">Logout</span>
    </div>
    <div v-else>
      <span @click="this.signinUser()" class="link">Login</span>
    </div>
    <router-view v-if="loggedInUser !== undefined" name="NavigationBar" />
  </header>
  <main v-if="loggedInUser !== undefined"><router-view /></main>
  <main v-else><router-view name="AuthorizedAccess" /></main>
  <notifications
    classes="vue-notification notification-customization"
    position="bottom right"
    :speed="1500"
  />
  <notifications
    group="progress"
    width="150px"
    classes="progress-notification"
    position="top right"
    :duration="0"
    :speed="600"
    :max="1"
  />
</template>
<script>
import NavMenu from "./components/NavMenu.vue";
export default {
  components: {
    NavMenu,
  },
  computed: {
    loggedInUser() {
      return this.user !== undefined && this.user.profile !== undefined
        ? this.user.profile.name + " <" + this.user.profile.email + ">"
        : undefined;
    },
  },
};
</script>
<style>
body {
  margin: 0;
}

table {
  margin-left: auto;
  margin-right: auto;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

header {
  display: block;
  background: #e8e9eb;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

#nav {
  padding: 5px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#nav a.dimmed {
  color: #a5a5a5;
}

.subheader {
  font-size: +4;
}

.details {
  text-align: left;
}

.name {
  font-weight: bold;
  padding-right: 1em;
  vertical-align: top;
}

.link {
  font-weight: normal !important;
  text-decoration: underline;
  cursor: pointer;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-container {
  display: table;
  margin: auto;
}

.left-aligned-text {
  text-align: left !important;
}

.right-aligned-text {
  text-align: right !important;
}

.bold {
  font-weight: bold;
}

.blinking {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.commit-id {
  font-family: monospace;
  border-style: dotted;
  border-color: #287ec7;
  border-width: 2px;
  border-radius: 8px;
}

.list,
.list th,
.list td {
  border: 1px solid dimgrey;
  border-collapse: collapse;
  text-align: left;
}

.notification-customization {
  font-size: 90%;
}
.progress-notification {
  text-align: right;
  background: transparent;
  font-size: 90%;
  margin-top: 1em;
  margin-right: 1em;
}

.fake-input {
  width: 0px;
  border: none;
  padding: 0px;
  margin: 0px;
  outline: none;
}
</style>
