<!--
 Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
-->

<template>
  <div>Redirecting<span class="blinking">...</span></div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>