/**
 * Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
 */

import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'
import { UserManager } from 'oidc-client-ts'
import App from './App.vue'
import RedirectingApp from './RedirectingApp.vue'
import router from './router'
import { store } from './stores'
import axios from './axios'

// import "../node_modules/ag-grid-community/styles/ag-grid.css";
// import "../node_modules/ag-grid-community/styles/ag-theme-balham.css";

let redirectUrl = new URL(window.location)
redirectUrl.hash = ''
const signInRedirect = redirectUrl.toString()
redirectUrl = new URL(window.location)
redirectUrl.search = '?auto-sign-in=false'
const signOutRedirect = redirectUrl.toString()

/*global appConfig*/
const userManagerConfig = {
    ...JSON.parse(process.env.VUE_APP_USER_AUTH ? process.env.VUE_APP_USER_AUTH : "{}"),
    ...appConfig.userAuth,
    redirect_uri: signInRedirect,
    post_logout_redirect_uri: signOutRedirect,
    automaticSilentRenew: true,
    loadUserInfo: true,
    scope: 'openid email profile',
}
const userManager = new UserManager(userManagerConfig)

const userErrorManager = function (err) {
    if (err.message === 'Popup closed by user') {
        return
    }
    if ((process.env.NODE_ENV !== 'production')) {
        throw err
    }
    else {
        console.error(err);
    }
}
const signinUser = function () {
    if (userManagerConfig.usePopup) {
        userManager.signinPopup().catch(userErrorManager)
    } else {
        userManager.signinRedirect({
            state: {
                hash: window.location.hash
            },
        }).catch(userErrorManager)
    }
}


const signoutUser = async function () {
    const url = await userManager.metadataService.getEndSessionEndpoint()
    if (url) {
        await userManager.signoutRedirect()
        return
    }
    await userManager.clearStaleState()
    await userManager.removeUser()
    window.history.replaceState(window.history.state, '', signOutRedirect)
    window.location.reload();
}

const runApp = function (userManager, user) {
    const app = createApp(App).use(Notifications).use(store).use(router)
    app.config.globalProperties.axios = axios
    app.config.globalProperties.userManager = userManager
    app.config.globalProperties.user = user
    if (user !== undefined) {
        let curUser = user
        userManager.events.addUserLoaded((user) => {
            curUser = user
        })
        app.config.globalProperties.axios.interceptors.request.use((config) => {
            config.headers.common['Authorization'] = "Bearer " + curUser.id_token;
            return config
        })
    }
    app.config.globalProperties.signinUser = signinUser
    app.config.globalProperties.signoutUser = signoutUser
    app.mount('#app')
}

if (window && (window.location.search.includes('session_state=') || window.location.hash.includes('session_state=')
    || (window.location.search.includes('state=') && window.location.search.includes('code=')))) {
    createApp(RedirectingApp).mount('#app')
    if (userManagerConfig.usePopup) {
        setTimeout(() => { userManager.signinPopupCallback() }, 750)
    } else {
        userManager.signinRedirectCallback().then((user) => {
            window.history.replaceState(window.history.state, '', window.location.pathname + user.state.hash)
            window.location.reload()
        })
    }
} else {
    userManager.getUser().then((user) => {
        if (!user) {
            userManager.events.addUserLoaded(async (user) => {
                await userManager.clearStaleState()
                console.log("user loaded " + user.profile.email)
                window.location.reload()
            })
            runApp(userManager, undefined)
            if (new URL(window.location).searchParams.get('auto-sign-in') !== 'false') {
                signinUser()
            }
            return
        }
        let locationUrl = new URL(window.location)
        locationUrl.searchParams.delete('auto-sign-in')
        window.location.search = locationUrl.search
        userManager.events.addAccessTokenExpired(async () => {
            console.log("access token expired")
            await userManager.clearStaleState()
            await userManager.removeUser()
            window.history.replaceState(window.history.state, '', window.location.pathname + window.location.hash)
            window.location.reload();
        })
        userManager.events.addAccessTokenExpiring(async () => {
            await userManager.clearStaleState()
            console.log("access token expiring")
        })
        runApp(userManager, user)
    })
}