/**
 * Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
 */

import { defineStore } from 'pinia'

export const useRecentStore = defineStore('recent', {
    state: () => ({
        recentDatasetID: "",
        recentDatasetFilterQuery: undefined,
        recentGraphURI: undefined,
        recentIBNodeInfo: undefined,
    }),
    persistedState: {
        includePaths: ["recentDatasetID", "recentGraphURI", "recentIBNodeInfo"],
        migrate: (state) => {
            if (state.recentNamespaceID) {
                state.recentDatasetID = state.recentNamespaceID
                delete state.recentNamespaceID
            }
            if (state.recentIBNodeInfo && state.recentIBNodeInfo.nsID) {
                state.recentIBNodeInfo.dsID = state.recentIBNodeInfo.nsID
                delete state.recentIBNodeInfo.nsID
            }
            return state
        },
        persist: true,
    },
})