/**
 * Copyright 2022 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
 */

import { defineStore } from 'pinia'

export const useDatasetEditSessions = defineStore('dsEditSessions', {
    state: () => ({
        sessions: new Map(),
        pendingSessions: new Map(),
        serviceSessions: new Map(),
    }),
    actions: {
        updateObject({ dsID, fragment, predicate, object }, updObj) {
            if (!this.sessions.has(dsID)) {
                this.sessions.set(dsID, new Map())
            }
            if (!this.sessions.get(dsID).has(fragment)) {
                this.sessions.get(dsID).set(fragment, new Map())
            }
            const tripleKey = JSON.stringify({ predicate: predicate, object: object })
            this.sessions.get(dsID).get(fragment).set(tripleKey, updObj)
        },
        updatedObject({ dsID, fragment, predicate, object }) {
            if (this.sessions.has(dsID) && this.sessions.get(dsID).has(fragment)) {
                const tripleKey = JSON.stringify({ predicate: predicate, object: object })
                if (this.sessions.get(dsID).get(fragment).has(tripleKey)) {
                    return this.sessions.get(dsID).get(fragment).get(tripleKey)
                }
            }
            return undefined
        },
        restoreObject({ dsID, fragment, predicate, object }) {
            if (!this.sessions.has(dsID)) {
                return
            }
            if (!this.sessions.get(dsID).has(fragment)) {
                return
            }
            const tripleKey = JSON.stringify({ predicate: predicate, object: object })
            this.sessions.get(dsID).get(fragment).delete(tripleKey)
            if (this.sessions.get(dsID).get(fragment).size === 0) {
                this.sessions.get(dsID).delete(fragment)
                if (this.sessions.get(dsID).size === 0) {
                    this.sessions.delete(dsID)
                }
            }
        },
        removePendingSession(dsID) {
            this.sessions.delete(dsID)
            this.pendingSessions.delete(dsID)
        },
    },
    persistedState: {
        persist: true,
    },
})