/**
 * Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
 */

import { create } from 'axios';
import { notify } from "@kyvg/vue3-notification";

const hostURL = (process.env.NODE_ENV === 'production') ? '' : 'http://' + location.hostname + ':9000'
const instance = create({
    hostURL: hostURL,
    baseURL: hostURL + '/api/v1',
    timeout: 300000,
});

var progressTimeoutId = undefined
var progressIntervalId = undefined

var startProgress = function () {
    stopProgress()
    progressTimeoutId = setTimeout(() => {
        progressTimeoutId = undefined
        notify({
            group: "progress",
            text: "Loading...",
        });
        progressIntervalId = setInterval(() => {
            notify({
                group: "progress",
                text: "Loading...",
            });
        }, 2000);
    }, 20);
}

var stopProgress = function () {
    notify({
        group: "progress",
        clean: true,
    });
    if (progressTimeoutId !== undefined) {
        clearTimeout(progressTimeoutId)
        progressTimeoutId = undefined
    }
    if (progressIntervalId !== undefined) {
        clearInterval(progressIntervalId)
        progressIntervalId = undefined
    }
}

instance.interceptors.request.use(function (config) {
    startProgress()
    return config;
}, function (error) {
    stopProgress()
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    stopProgress()
    return response;
}, function (error) {
    notify({
        title: error.name,
        text: error.message + (error.response && error.response.data ? ":<br><pre>" + error.response.data + "</pre>" : ""),
        type: "error",
    });
    stopProgress()
    return Promise.reject(error);
});
export default instance
