/**
 * Copyright 2021 Semantic STEP Technology GmbH, Germany & DCT Co., Ltd. Tianjin, China
 */

import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'SSTRepository',
    components: {
      default: () => import('../views/SSTRepository.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    }
  },
  {
    path: '/parts',
    name: 'Parts',
    components: {
      default: () => import('../views/PartPage.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    }
  },
  {
    path: '/partDesign',
    name: 'PartDesign',
    components: {
      default: () => import('../views/PartDesign.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    }
  },
  {
    path: '/dataset/:dsID?',
    name: 'SSTDataset',
    props: true,
    components: {
      default: () => import('../views/SSTDataset.vue'),
      NavigationBar: () => import('../components/SSTDatasetNavigation.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    },
  },
  {
    path: '/dataset/:dsID?/graph/:graphURI?',
    name: 'SSTDatasetGraph',
    props: true,
    components: {
      default: () => import('../views/SSTDataset.vue'),
      NavigationBar: () => import('../components/SSTDatasetNavigation.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    },
  },
  {
    path: '/dataset/:dsID?/graph/:graphURI?/node/:fragment?',
    name: 'IBNode',
    props: true,
    components: {
      default: () => import('../views/IBNode.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    }
  },
  {
    path: '/dataset/:dsID/commit/:commitID',
    name: 'SSTDatasetCommit',
    props: true,
    components: {
      default: () => import('../views/SSTDataset.vue'),
      NavigationBar: () => import('../components/SSTDatasetNavigation.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    },
  },
  {
    path: '/dataset/:dsID/commit/:commitID/graph/:graphURI?',
    name: 'SSTDatasetCommitGraph',
    props: true,
    components: {
      default: () => import('../views/SSTDataset.vue'),
      NavigationBar: () => import('../components/SSTDatasetNavigation.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    },
  },
  {
    path: '/dataset/:dsID/commit/:commitID/graph/:graphURI?/node/:fragment?',
    name: 'CommitIBNode',
    props: true,
    components: {
      default: () => import('../views/IBNode.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "unauthorized" */'../components/AuthorizedAccess.vue'),
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    components: {
      default: () => import(/* webpackChunkName: "about" */  '../views/About.vue'),
      AuthorizedAccess: () => import(/* webpackChunkName: "about" */'../views/About.vue'),
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(savedPosition ? savedPosition : { x: 0, y: 0 })
      }, 500)
    })
  }
})

export default router
